@charset "UTF-8";
:root {
  --atom-size: 40px;
  --atom-color-hex: #E42C64;
  --atom-color-rgb: 228, 44, 100;
  --nucleus-size: calc(var(--atom-size) / 5);
  --electron-color-hex: #E42C64;
  --electron-size: calc(var(--atom-size) / 25);
  --electron-orbit-size: calc(var(--atom-size) / 2.5);
  --electron-speed: 1.2s;
  --electron-speed-alpha: 1s;
  --electron-speed-omega: .8s;
}

.left-custom-gap {
  left: 24px;
}

.top-custom-gap {
  top: 24px;
}

.right-custom-gap {
  right: 24px;
}

.bottom-custom-gap {
  bottom: 24px;
}

.my-pink-bg {
  background-color: #ff4c82;
}

.details-color {
  color: #ff4c82;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  color: #FAEDF0;
}

body {
  overflow: overlay;
}

#app {
  overflow: hidden;
}

.app-container {
  background-color: #000000;
}

#enter-button {
  color: #ff4c82;
  font-weight: 700;
  font-size: 18px;
  transition: all 0.5s ease-in-out;
}

.canvas-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #1d1e22, #121317);
  background: linear-gradient(45deg, #000000, #000000);
}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 2s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.gradientOn {
  opacity: 1;
  background: radial-gradient(at 50% -150%, #121B74 10px, 15%, rgba(0, 0, 0, 0) 75%);
}

@keyframes colorChange {
  to {
    transform: translateX(50%);
  }
}
@-webkit-keyframes colorChange {
  to {
    -webkit-transform: translateX(50%);
  }
}
@keyframes fadeAway {
  0% {
    visibility: visible;
    opacity: 1;
  }
  90% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 0;
    filter: blur(10px);
    visibility: hidden;
  }
}
@-webkit-keyframes fadeAway {
  0% {
    visibility: visible;
    opacity: 1;
  }
  90% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 0;
    filter: blur(10px);
    visibility: hidden;
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  z-index: 30;
  animation: fadeAway 1s forwards ease-in;
}
.mask .svs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.atom {
  position: fixed;
  z-index: 5;
  width: var(--atom-size);
  height: var(--atom-size);
  animation: 8s atom infinite cubic-bezier(1, 0.25, 0, 0.75);
  top: 24px;
  left: 24px;
}
@media screen and (min-width: 768px) {
  .atom {
    top: 0;
    left: 0;
    position: relative;
  }
}

@keyframes atom {
  0% {
    transform: rotate(0deg) scale(1);
  }
  12.5% {
    transform: rotate(-45deg) scale(0.9);
  }
  25% {
    transform: rotate(-90deg) scale(1);
  }
  37.5% {
    transform: rotate(-135deg) scale(0.9);
  }
  50% {
    transform: rotate(-180deg) scale(1);
  }
  62.5% {
    transform: rotate(-225deg) scale(0.9);
  }
  75% {
    transform: rotate(-270deg) scale(1);
  }
  87.5% {
    transform: rotate(-315deg) scale(0.9);
  }
  100% {
    transform: rotate(-360deg) scale(1);
  }
}
@-webkit-keyframes atom {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
  }
  12.5% {
    -webkit-transform: rotate(-45deg) scale(0.9);
  }
  25% {
    -webkit-transform: rotate(-90deg) scale(1);
  }
  37.5% {
    -webkit-transform: rotate(-135deg) scale(0.9);
  }
  50% {
    -webkit-transform: rotate(-180deg) scale(1);
  }
  62.5% {
    -webkit-transform: rotate(-225deg) scale(0.9);
  }
  75% {
    -webkit-transform: rotate(-270deg) scale(1);
  }
  87.5% {
    -webkit-transform: rotate(-315deg) scale(0.9);
  }
  100% {
    -webkit-transform: rotate(-360deg) scale(1);
  }
}
/* Nucleus */
.atom::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--nucleus-size);
  height: var(--nucleus-size);
  margin-top: calc(var(--nucleus-size) / -2);
  margin-left: calc(var(--nucleus-size) / -2);
  background: var(--electron-color-hex); /* var(--atom-color-hex); */
  border-radius: 100%;
  box-shadow: 0 0 3px 0 var(--atom-color-hex);
  animation: 2s nucleus infinite cubic-bezier(0.65, 0, 0.35, 1);
}

@keyframes nucleus {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes nucleus {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(0.85);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
/* Electron Orbit */
.atom > [class^=electron] {
  border-top: solid rgba(var(--atom-color-rgb), 0.45) 1px;
  border-right: solid rgba(var(--atom-color-rgb), 0.35) 2px;
  border-bottom: solid rgba(var(--atom-color-rgb), 0.3) 4px;
  border-left: solid rgba(var(--atom-color-rgb), 0) 2px;
  border-radius: 100%;
  width: 100%;
  height: var(--electron-orbit-size);
  position: absolute;
  top: 50%;
  margin-top: calc(var(--electron-orbit-size) / -2);
}

.atom > .electron-alpha {
  transform: rotate(60deg);
}

.atom > .electron-omega {
  transform: rotate(-60deg);
}

/* Electron Unit */
.atom [class^=electron]::after {
  content: "";
  display: block;
  width: var(--electron-size);
  height: var(--electron-size);
  background: var(--electron-color-hex);
  border-radius: 50%;
  margin-top: calc(var(--electron-size) / -2);
  position: absolute;
  top: 50%;
  left: calc(var(--electron-size) / -1);
  transform: scale(1);
  animation: calc(var(--electron-speed) * 2) electron infinite ease-in-out;
}

.atom .electron-alpha::after {
  animation: calc(var(--electron-speed-alpha) * 2) electron infinite ease-in-out;
}

.atom .electron-omega::after {
  animation: calc(var(--electron-speed-omega) * 2) electron infinite ease-in-out;
}

@keyframes electron {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.5);
  }
  25% {
    transform: scale(1);
  }
  37.5% {
    transform: scale(0.25);
  }
  50% {
    transform: scale(1);
  }
  62.5% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(1);
  }
  87.5% {
    transform: scale(0.25);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes electron {
  0% {
    -webkit-transform: scale(1);
  }
  12.5% {
    -webkit-transform: scale(1.5);
  }
  25% {
    -webkit-transform: scale(1);
  }
  37.5% {
    -webkit-transform: scale(0.25);
  }
  50% {
    -webkit-transform: scale(1);
  }
  62.5% {
    -webkit-transform: scale(1.5);
  }
  75% {
    -webkit-transform: scale(1);
  }
  87.5% {
    -webkit-transform: scale(0.25);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes turnIt {
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}
@-webkit-keyframes turnIt {
  100% {
    -webkit-transform: rotate3d(1, 1, 1, 360deg);
  }
}
.container {
  transition: all 1s ease-in-out;
}

.faded-right {
  transform: translateX(200%);
  opacity: 0;
}

.faded-left {
  transform: translateX(-200%);
  opacity: 0;
}

.faded {
  opacity: 0;
}

.social-links-nav, .links-nav {
  right: 24px;
  transition: all 1s ease-in-out;
}
.social-links-nav li, .links-nav li {
  border-radius: 50px;
}

.social-links-nav li, .links-nav li {
  margin: 6px 0;
}

.links-nav {
  top: 0;
  position: fixed;
  right: 0;
  transition: all 0.4s ease-in-out;
}
@media screen and (max-width: 768px) {
  .links-nav {
    width: 0;
  }
  .links-nav.open {
    width: 100vw;
  }
}
@media screen and (min-width: 768px) {
  .links-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 36px;
    transition: background-color 1s ease-in-out;
  }
  .links-nav.background {
    background-color: #121B74;
    will-change: background-color;
  }
}
.links-nav .menu-toggle {
  background-color: #ff4c82;
  border-radius: 50%;
  box-shadow: 0 2px 8px #ff4c82;
  cursor: pointer;
  height: 56px;
  right: 24px;
  position: fixed;
  top: 24px;
  width: 56px;
  z-index: 20;
}
.links-nav .menu-toggle-bar {
  background-color: #ffffff;
  bottom: 0;
  height: 2px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: 16px;
  top: 0;
  transition: all 0.3s ease-in-out;
}
.links-nav .menu-toggle-bar:first-child {
  transform: translate3d(0, -4px, 0);
}
.links-nav .menu-toggle-bar:last-child {
  transform: translate3d(0, 4px, 0);
}
.links-nav .menu-toggle.toggled {
  background-color: #fff;
}
.links-nav .menu-toggle.toggled .menu-toggle-bar:first-child {
  transform: rotate(135deg) translate3d(0, 0, 0);
  background-color: #ff4c82;
}
.links-nav .menu-toggle.toggled .menu-toggle-bar:last-child {
  transform: rotate(-135deg) translate3d(0, 0, 0);
  background-color: #ff4c82;
}
.links-nav .menu-toggle.toggled .menu-toggle-bar:nth-child(2) {
  opacity: 0;
}
.links-nav ul {
  height: 100vh;
  background: black;
  background-color: #ff4c82;
  transform: translateX(100%);
  opacity: 0;
  width: 100vw;
  height: 100vh;
  padding: 80px 20px;
}
@media screen and (min-width: 768px) {
  .links-nav ul {
    transition: transform 0.5s ease-in-out;
    opacity: 1;
    top: 0;
    right: 0;
    transform: none;
    background: transparent;
    width: fit-content;
    height: fit-content;
    padding: 0;
  }
}
.links-nav ul.open {
  transform: none;
  opacity: 1;
}
.links-nav ul li {
  transition: all 0.5s ease-in-out;
  margin: 50px;
}
@media screen and (min-width: 768px) {
  .links-nav ul li {
    height: fit-content;
    padding: 0 10px;
    margin: 0 10px;
    font-size: 14px;
  }
  .links-nav ul li:last-of-type {
    padding-right: 0;
    margin-right: 0;
  }
}
.links-nav ul li.resume {
  position: relative;
  pointer-events: all;
  cursor: pointer;
  background: transparent;
  border: 2px solid #ff4c82;
  color: #ff4c82;
  border-radius: 5px;
  /*--- btn-2 ---*/
  /* 6. hover-slide-down */
  border-radius: 5px;
  padding: 6px 10px;
}
.links-nav ul li.resume::before {
  position: absolute;
  z-index: -1;
  content: "";
  background-color: rgba(228, 44, 100, 0.2);
  transition: 0.3s ease-out;
}
.links-nav ul li.resume:hover {
  color: rgb(255, 255, 255);
  transition: 0.2s 0.1s;
}
.links-nav ul li.resume::before {
  top: 0;
  left: 0;
  right: 0;
  height: 0%;
  width: 100%;
}
.links-nav ul li.resume:hover::before {
  height: 100%;
}

@media screen and (min-width: 768px) {
  .links-nav.background ul {
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
}

#introduction {
  background: linear-gradient(320deg, #121B74 25%, transparent 25%);
  background-position-x: 100vw;
  min-height: 100vh;
  transition: background-position-x 1s ease-in-out;
  min-height: 100vh;
  background-repeat: no-repeat;
  pointer-events: all;
}
#introduction.moved-in {
  background-position-x: 0;
}
@media screen and (min-width: 608px) {
  #introduction {
    background: linear-gradient(340deg, #121B74 30%, transparent 30%);
    background-position-x: 100vw;
    background-repeat: no-repeat;
  }
}

#introduction * {
  transition: 1s opacity ease-in-out, 1s transform ease-in-out;
}

#introduction h1, #introduction h2 {
  will-change: opacity;
}
#introduction h3 {
  margin-top: 1rem;
}
#introduction a {
  position: relative;
  pointer-events: all;
  cursor: pointer;
  background: transparent;
  border: 2px solid #ff4c82;
  color: #ff4c82;
  border-radius: 5px;
  /*--- btn-2 ---*/
  /* 6. hover-slide-down */
  margin-top: 2rem;
}
#introduction a::before {
  position: absolute;
  z-index: -1;
  content: "";
  background-color: rgba(228, 44, 100, 0.2);
  transition: 0.3s ease-out;
}
#introduction a:hover {
  color: rgb(255, 255, 255);
  transition: 0.2s 0.1s;
}
#introduction a::before {
  top: 0;
  left: 0;
  right: 0;
  height: 0%;
  width: 100%;
}
#introduction a:hover::before {
  height: 100%;
}

#about-me {
  background: #121B74;
  min-height: 100vh;
}

.heading {
  font-size: clamp(40px, 8vw, 80px);
  line-height: 1;
}

section {
  padding: 40px 24px;
  z-index: 1;
}
@media (min-width: 468px) {
  section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  section {
    padding-left: 15rem;
    padding-right: 15rem;
  }
}
@media (min-width: 1324px) {
  section {
    padding-left: 20rem;
    padding-right: 20rem;
  }
}

.section-heading {
  font-size: clamp(30px, 4vw, 40px);
  font-weight: 700;
  color: #ff4c82;
}

section p {
  line-height: 1.5;
  font-size: 18px;
  margin-top: 2rem;
}
@media screen and (min-width: 481px) {
  section p {
    width: 50%;
    font-size: 14px;
  }
}

#about-me * {
  transition: 1s opacity ease-in-out, 1s transform ease-in-out;
}
#about-me * ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
}
#about-me * li {
  padding-left: 20px;
  position: relative;
}
#about-me * li::before {
  content: "∴";
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  color: #ff4c82;
  line-height: 12px;
}
#about-me * li span {
  will-change: opacity, transform;
}
#about-me .section-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column-reverse;
}
@media screen and (min-width: 620px) {
  #about-me .section-container {
    flex-direction: row;
  }
  #about-me .section-container > div:first-of-type {
    width: 50%;
  }
}
#about-me .section-container p {
  width: 100%;
}
#about-me .section-container .portrait {
  margin-top: 60px;
  margin-bottom: 30px;
  position: relative;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
@media screen and (min-width: 620px) {
  #about-me .section-container .portrait {
    margin-left: 5rem;
  }
}
#about-me .section-container .portrait img {
  filter: grayscale(1);
  border-radius: 10px;
}
#about-me .section-container .portrait:hover:before {
  z-index: 2;
  bottom: 1rem;
  right: 1rem;
  transform: rotate3d(10, 10, 10, 145deg);
}
#about-me .section-container .portrait:hover:after {
  z-index: 2;
  top: 1rem;
  left: 1rem;
}
#about-me .section-container .portrait:before, #about-me .section-container .portrait:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 0.3s ease;
  border-radius: 10px;
}
#about-me .section-container .portrait:before {
  background: #070608;
  opacity: 0.2;
  z-index: -1;
  bottom: 1.5rem;
  right: 1.5rem;
}
#about-me .section-container .portrait:after {
  background: #ff4c82;
  opacity: 0.2;
  z-index: -1;
  top: 1.5rem;
  left: 1.5rem;
}

#projects {
  min-height: 100vh;
  width: 100%;
  background-color: #121B74;
}
#projects .section-heading {
  align-self: self-end;
  margin-bottom: 0.5rem;
  transition: 1s opacity ease-in-out, 1s transform ease-in-out;
}
#projects .section-container {
  width: 100%;
}
#projects .section-container--project {
  position: relative;
  height: fit-content;
  width: 100%;
  overflow: hidden;
  margin: 2rem 0;
}
@media screen and (min-width: 520px) {
  #projects .section-container--project {
    height: 40vh;
  }
}
#projects .section-container--project.faded-project-container:nth-of-type(even) {
  transform: translateX(200%);
  opacity: 0;
}
#projects .section-container--project ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 20px;
}
@media screen and (min-width: 780px) {
  #projects .section-container--project ul {
    margin-top: 0;
  }
}
#projects .section-container--project li {
  padding: 0 20px;
  position: relative;
}
#projects .section-container--project li::before {
  content: "∴";
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  color: #ff4c82;
  line-height: 12px;
}
#projects .section-container--project:nth-of-type(even) .image-container {
  left: 0;
}
@media screen and (min-width: 520px) {
  #projects .section-container--project:nth-of-type(even) .section-container--project-info {
    right: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
#projects .section-container--project:nth-of-type(even) .faded-project {
  transform: translateX(200%);
  opacity: 0;
}
#projects .section-container--project:nth-of-type(odd) .image-container {
  right: 0;
}
#projects .section-container--project:nth-of-type(odd) .section-container--project-info {
  left: 0;
}
#projects .section-container--project:nth-of-type(odd) .faded-project {
  transform: translateX(-200%);
  opacity: 0;
}
#projects .section-container--project-info {
  transition: 1s opacity ease-in-out;
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
}
@media screen and (min-width: 520px) {
  #projects .section-container--project-info {
    width: unset;
    padding: 0;
    position: absolute;
  }
}
#projects .section-container--project-info--title {
  font-size: clamp(20px, 2vw, 30px);
  font-weight: 700;
}
#projects .section-container--project-info--description {
  line-height: 1.5;
  font-size: 18px;
  margin: 1.5rem 0;
  pointer-events: all;
}
@media screen and (min-width: 520px) {
  #projects .section-container--project-info--description {
    font-size: 14px;
    padding: 20px;
    background: #062743;
    width: 70%;
    border-radius: 5px;
    box-shadow: 2px 2px 4px #062743;
  }
}
#projects .section-container--project-info--link {
  color: #FAEDF0;
  pointer-events: all;
  width: fit-content;
}
#projects .section-container--project-info .section-container--project-info--link img {
  width: 15px;
  height: 15px;
}
#projects .section-container--project .image-container {
  position: absolute;
  border-radius: 5px;
  opacity: 0;
  overflow: hidden;
  top: 40%;
  transform: scale(4) translateX(-30%);
  animation: travel 35s linear infinite;
}
@media screen and (min-width: 520px) {
  #projects .section-container--project .image-container {
    animation: none;
    transform: none;
    width: 60%;
  }
}
#projects .section-container--project .image-container.turned-on {
  opacity: 0.2;
}
@media screen and (min-width: 520px) {
  #projects .section-container--project .image-container.turned-on {
    opacity: 1;
  }
}
#projects .section-container--project .image-container.turned-on img {
  animation: turn-on 4s linear;
  animation-fill-mode: forwards;
}
#projects .section-container--project .image-container.turned-on:hover {
  filter: brightness(1.2);
  cursor: pointer;
}
#projects .section-container--project .image-container::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}
#projects .section-container--project .image-container::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
}
@media screen and (min-width: 520px) {
  #projects .section-container--project .image-container::before {
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    background-size: 100% 2px, 3px 100%;
  }
}

#contact {
  padding: 0;
  height: 100vh;
}
#contact * {
  transition: 1s opacity ease-in-out, 1s transform ease-in-out;
}
#contact a {
  position: relative;
  pointer-events: all;
  cursor: pointer;
  background: transparent;
  border: 2px solid #ff4c82;
  color: #ff4c82;
  border-radius: 5px;
  /*--- btn-2 ---*/
  /* 6. hover-slide-down */
}
#contact a::before {
  position: absolute;
  z-index: -1;
  content: "";
  background-color: rgba(228, 44, 100, 0.2);
  transition: 0.3s ease-out;
}
#contact a:hover {
  color: rgb(255, 255, 255);
  transition: 0.2s 0.1s;
}
#contact a::before {
  top: 0;
  left: 0;
  right: 0;
  height: 0%;
  width: 100%;
}
#contact a:hover::before {
  height: 100%;
}

@keyframes flicker {
  0% {
    opacity: 0.4593397623;
  }
  5% {
    opacity: 0.5468734477;
  }
  10% {
    opacity: 0.5221435408;
  }
  15% {
    opacity: 0.552463235;
  }
  20% {
    opacity: 0.8626508508;
  }
  25% {
    opacity: 0.6465521996;
  }
  30% {
    opacity: 0.8301117037;
  }
  35% {
    opacity: 0.8974552847;
  }
  40% {
    opacity: 0.7408524214;
  }
  45% {
    opacity: 0.6449629055;
  }
  50% {
    opacity: 0.8646454541;
  }
  55% {
    opacity: 0.7893944617;
  }
  60% {
    opacity: 0.7668378144;
  }
  65% {
    opacity: 0.4087963584;
  }
  70% {
    opacity: 0.3904626142;
  }
  75% {
    opacity: 0.3583168168;
  }
  80% {
    opacity: 0.4045787304;
  }
  85% {
    opacity: 0.2291380197;
  }
  90% {
    opacity: 0.0681819079;
  }
  95% {
    opacity: 0.8672770214;
  }
  100% {
    opacity: 0.9476698925;
  }
}
@-webkit-keyframes flicker {
  0% {
    -webkit-opacity: 0.3136981115;
  }
  5% {
    -webkit-opacity: 0.358130452;
  }
  10% {
    -webkit-opacity: 0.6931827937;
  }
  15% {
    -webkit-opacity: 0.7599075028;
  }
  20% {
    -webkit-opacity: 0.6892425732;
  }
  25% {
    -webkit-opacity: 0.7036925288;
  }
  30% {
    -webkit-opacity: 0.6734233686;
  }
  35% {
    -webkit-opacity: 0.0689783142;
  }
  40% {
    -webkit-opacity: 0.5013714123;
  }
  45% {
    -webkit-opacity: 0.3108165108;
  }
  50% {
    -webkit-opacity: 0.016503429;
  }
  55% {
    -webkit-opacity: 0.2347791752;
  }
  60% {
    -webkit-opacity: 0.8184314862;
  }
  65% {
    -webkit-opacity: 0.6378249297;
  }
  70% {
    -webkit-opacity: 0.3953812234;
  }
  75% {
    -webkit-opacity: 0.4224737484;
  }
  80% {
    -webkit-opacity: 0.3697938135;
  }
  85% {
    -webkit-opacity: 0.117081545;
  }
  90% {
    -webkit-opacity: 0.1983519129;
  }
  95% {
    -webkit-opacity: 0.8700705699;
  }
  100% {
    -webkit-opacity: 0.5190374146;
  }
}
@keyframes turn-on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    filter: brightness(30);
    opacity: 1;
  }
  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }
  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }
  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    filter: brightness(30);
    opacity: 0;
  }
  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(0) brightness(0);
    opacity: 0;
  }
  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1;
  }
}
@-webkit-keyframes turn-on {
  0% {
    -webkit-transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(30);
    -webkit-filter: brightness(30);
    -webkit-opacity: 1;
  }
  3.5% {
    -webkit-transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }
  3.6% {
    -webkit-transform: scale(1, 0.8) translate3d(0, -100%, 0);
    -webkit-opacity: 1;
  }
  9% {
    -webkit-transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(30);
    -webkit-opacity: 0;
  }
  11% {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
    -webkit-opacity: 1;
  }
}
@keyframes expand {
  100% {
    max-height: 400px;
    opacity: 0.5;
  }
  0% {
    max-height: 0;
    opacity: 0;
  }
}
@-webkit-keyframes expand {
  100% {
    -webkit-max-height: 400px;
    -webkit-opacity: 0.5;
  }
  0% {
    -webkit-max-height: 0;
    -webkit-opacity: 0;
  }
}
@keyframes travel {
  0% {
    transform: scale(4) translateX(-30%);
  }
  100% {
    transform: scale(4) translateX(30%);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes travel {
  0% {
    -webkit-transform: scale(4) translateX(-30%);
  }
  100% {
    -webkit-transform: scale(4) translateX(30%);
  }
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}